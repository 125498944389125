
import { createContext } from "react";
import { useState } from "react";

export const appContext = createContext()

function ContextProvider({ children }) {
    const [IsLogin, setIsLogin] = useState();
    const [SessionUser, setSessionUser] = useState(null);

    return <appContext.Provider value={{
        value: {
            IsLogin,
            SessionUser
        },
        setIsLogin,
        setSessionUser
    }}>
        {children}
    </appContext.Provider>
}

export default ContextProvider