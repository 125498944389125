import React from "react";
import Hero from './components/Hero'
import Navbar from './components/Navbar';
import { createClient } from "@supabase/supabase-js";
import { appContext } from "./context";
const supabase = createClient(process.env.REACT_APP_URL, process.env.REACT_APP_ANON_KEY)

function App() {
  const [session, setSession] = React.useState(null);
  const {
    setSessionUser
  } = React.useContext(appContext);

  React.useEffect(() => {
    setSession(supabase.auth.getSession());

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  React.useEffect(() => {
    if (session?.user !== undefined) {
      setSessionUser(session.user);
    }
  }, [session]);

  return (
    <div>
      <Navbar />
      <Hero />
    </div>
  );
}

export default App;
