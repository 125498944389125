import React from 'react';
import { appContext } from "../context";
import { createClient } from '@supabase/supabase-js'

import bg from '../assets/background.png'
import x from '../assets/x.png'
import tele from '../assets/telegram.png'
import logo from '../assets/logo.png'

const supabase = createClient(process.env.REACT_APP_URL, process.env.REACT_APP_ANON_KEY)
const supabaseTable = createClient(process.env.REACT_APP_URL, process.env.REACT_APP_ANON_KEY)

const Hero = () => {
    const [IsAuth, setIsAuth] = React.useState(false);
    const [IsTweet, setIsTweet] = React.useState(false);
    const [IsVerified, setIsVerified] = React.useState(false);
    const {
        value
    } = React.useContext(appContext);
    const {
        SessionUser
    } = value;

    async function signInWithTwitter() {
        const { data, error } = await supabase.auth.signInWithOAuth({
            provider: 'twitter',
        });
    }

    async function saveData() {
        const { error } = await supabaseTable
            .from('twitterUser')
            .insert([
                {
                    user_id: SessionUser?.user_metadata?.provider_id,
                    user_metadata: {
                        email: SessionUser?.user_metadata?.email,
                        full_name: SessionUser?.user_metadata?.full_name,
                        user_name: '@' + SessionUser?.user_metadata?.user_name,
                        provider_id: SessionUser?.user_metadata?.provider_id,
                        avatar_url: SessionUser?.user_metadata?.avatar_url,
                    },
                    is_tweet: true
                },
            ])
            .select()

        if (error) {
            alert('Something wrong, please try again!');
        }

        setIsVerified(true);

        const url = "https://ctt.ac/HEb87";
        window.open(url, "_blank");
    }

    React.useEffect(() => {
        if (SessionUser === null) {
            setIsTweet(false);
        }
    }, [SessionUser]);

    const checkData = async () => {
        let { data: twitterUser, error } = await supabaseTable
            .from('twitterUser')
            .select("*")
            .eq('user_id', SessionUser?.user_metadata?.provider_id)

        if (error) {
            alert('Something wrong, please try again!');
            return;
        }

        if (twitterUser.length === 0) {
            return false;
        } else {
            return true;
        }
    };

    return (

        <div className='text-white font-engravers font-bold'>
            <img className='object-cover h-[100%] w-full absolute -z-10' src={bg} alt="" />
            <div className='w-full h-[100vh] mx-auto text-center flex flex-col justify-center'>
                <img className='h-9 w-9 mx-auto' src={logo} alt="" />
                <h1 className='md:text-7xl sm:text-6xl text-4xl'><span className='text-[#F0B90B]'>CLICK</span>COIN</h1>
                <div className='flex justify-center items-center'>
                    <p className='text-lg'>
                        Just click to Earn $CLICK
                    </p>
                </div>
                {IsTweet ?
                    <div className='my-8'>
                        <p className='text-sm'>Tweet to get early access</p>
                        <div className='flex justify-center items-center space-x-2'>
                            <div>
                                <button onClick={async () => {

                                    if (!IsVerified) {
                                        saveData();
                                    } else {
                                        alert('You already get early access!');
                                        const url = "https://ctt.ac/HEb87";
                                        window.open(url, "_blank");
                                    }
                                }} className='text-[#F0B90B] cursor-pointer underline text-lg'>Click here to tweet</button>
                            </div>
                            <div>
                                <input id="default-checkbox" type="checkbox" checked={IsVerified} value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" disabled />
                            </div>
                        </div>
                    </div>
                    :
                    <div className='flex flex-col py-10'>
                        {SessionUser === null ?
                            <button onClick={signInWithTwitter} className='bg-[#F0B90B] w-[200px] rounded-full font-bold my-2 mx-auto py-2 text-white'>CONNECT X</button>
                            :
                            <button onClick={async () => {
                                const dataUser = await checkData();

                                if (dataUser) {
                                    setIsVerified(true);
                                    setIsTweet(true);
                                } else {
                                    setIsTweet(true);
                                }
                            }} className='bg-[#F0B90B] w-[200px] rounded-full font-bold my-2 mx-auto py-2 text-white'>EARLY ACCESS</button>
                        }
                    </div>
                }
                <div className='text-lg'>Join Us:</div>
                <div className='flex justify-center'>
                    <a className='h-full m-2' href="https://twitter.com/clickcoinbsc" target='blank'>
                        <button className='h-full w-full'>
                            <img className='h-7 w-7 mx-auto' src={x} alt="" />
                        </button>
                    </a>
                    <a className='h-full m-2' href="#" target='blank'>
                        <button className='h-full w-full'>
                            <img className='h-7 w-7 mx-auto' src={tele} alt="" />
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Hero;
